
import { auto } from "@popperjs/core";
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  props: ["showTrialExpiredModal", "handleShowTrialExpiredModal"],
  setup(props: any) {
    const store = useStore();
    const currentUser = computed(() => store.getters.currentUser);
    const currentUserAts = computed(() => store.getters.currentUserAts);
    const closeModal = () => {
      props.handleShowTrialExpiredModal(false);
    };

    const atsAuthUrl = ref(
      `${process.env.VUE_APP_URL}/#/users?step=4&subscription=true`
    );

    const paymentMethod = () => {
      window.location.href = atsAuthUrl.value;
      window.location.reload();
    };

    return {
      closeModal,
      atsAuthUrl,
      currentUser,
      currentUserAts,
      paymentMethod
    };
  }
};
